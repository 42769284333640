import React from "react";
import Head from "next/head";
import Admin from "layouts/Admin.js";
import CardsHeader from "components/Headers/CardsHeader.js";
import useAppContext from 'context/state';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

function NotFound(props) {
  const api = useAppContext();
  return (
    <>
      <Head>
        <title>Solar Dash:Not Found</title>
      </Head>
      <CardsHeader
        name="Under Construction"
        parentName="Under Construction"
        // startDate={api.startDate}
        // endDate={api.endDate}
        // onDateChange={api.onDateChange}
        // plant_id={api.plant_id}
        // meter_id={api.currentMeter}
        // plant={api.plant_id}

      />
       <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card style={{ height: "40rem" }}>
              <CardHeader className="bg-transparent">
              </CardHeader>
              <CardBody>
                <div className="chart" style={{ height: "30rem", textAlign:"center"}}>
                    <p>Page Under Construction !!!</p>
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i className="ni ni-spaceship" />
                    </div>
                  
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>



      </Container>

    </>
  );
}

NotFound.layout = Admin;
export default NotFound;
